import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingScreen from '~components/loading-screen';
type AuthGuardProps = {
  children: React.ReactNode;
  noVisitors?: boolean;
};
function AuthGuard({
  children,
  noVisitors
}: AuthGuardProps) {
  const {
    pathname,
    push
  } = useRouter();
  const {
    user
  } = useSelector((state: any) => state.auth);
  const [rendered, setRendered] = useState(false);
  const checkAuth = async () => {
    const isLoginPage = pathname === '/login';
    const isVisitor = user?.userDto?.role?.name === 'Visitor';

    // Handle unauthenticated users or visitors based on `noVisitors`
    if (!user || isVisitor) {
      if (!isLoginPage && noVisitors) {
        await push('/login');
      }
    }
    setRendered(true);
  };
  useEffect(() => {
    checkAuth();
  }, []);
  return <>{rendered ? children : <LoadingScreen />}</>;
}
export default AuthGuard;